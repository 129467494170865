export const toggleAdDisclosure = ({ button, content, closeButton }) => {
	const disclosureButton = document.querySelector(button);
	const disclosureContentCloseButton = document.querySelector(closeButton);

	const toggleDisclosureContent = () => {
		const disclosureContent = document.querySelector(content);
		const frameDelay = 150;

		const contentOpenState =
			disclosureContent.dataset.open === "true" ? "false" : "true";

		if (contentOpenState === "true") {
			disclosureContent.style.display = "flex";

			// Wait for the next frame to apply the changes after changing to `tw-grid` to allow the content to fade in
			requestAnimationFrame(() => {
				disclosureButton.setAttribute("aria-expanded", contentOpenState);
				disclosureContent.dataset.open = contentOpenState;
			});

			return;
		}

		disclosureButton.setAttribute("aria-expanded", contentOpenState);
		disclosureContent.dataset.open = contentOpenState;

		// Wait for the next frame to apply the changes after fading out the content to hide the content
		setTimeout(() => {
			disclosureContent.style.display = "none";
		}, frameDelay);
	};

	disclosureButton.addEventListener("click", toggleDisclosureContent);
	disclosureContentCloseButton.addEventListener(
		"click",
		toggleDisclosureContent,
	);
};
